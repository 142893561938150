import React from 'react'
const Paper = ({heading, desc})=>{
    const b = heading.indexOf(' ')
    const a = heading.slice(0,b)
    const c = heading.slice(b)
    return (
        
        <div className="grid paper">
                    <grid className='paper-heading '>
                    <span className="text-purple-secondary">{a} </span> 
                    {c}
                    </grid>
                    <grid className='paper-desc'>
                        {desc}
                    </grid>
                 </div>
    );
}
export default Paper;