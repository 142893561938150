// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.content {

    box-sizing: border-box;

    height: calc(100vh - 124px);

    overflow: auto
}

@media not all and (min-width: 1400px) {

    .content {

        height: calc(100vh - 120.6px)
    }
}

@media not all and (min-width: 820px) {

    .content {

        height: calc(100vh - 160px)
    }
}

@media not all and (min-width: 640px) {

    .content {

        height: calc(100vh - 146px)
    }
}

.root {

    box-sizing: border-box;

    height: 100vh
}
`, "",{"version":3,"sources":["webpack://./src/pages/Home.css"],"names":[],"mappings":"AACE;;IAAA,sBAAuJ;;IAAvJ,2BAAuJ;;IAAvJ;AAAuJ;;AAAvJ;;IAAA;;QAAA;IAAuJ;AAAA;;AAAvJ;;IAAA;;QAAA;IAAuJ;AAAA;;AAAvJ;;IAAA;;QAAA;IAAuJ;AAAA;;AAIvJ;;IAAA,sBAA0B;;IAA1B;AAA0B","sourcesContent":[".content {\n  @apply h-[calc(100vh_-_124px)] max-md:h-[calc(100vh_-_160px)] max-sm:h-[calc(100vh_-_146px)] max-xl:h-[calc(100vh_-_120.6px)]  overflow-auto box-border;\n}\n\n.root {\n  @apply h-screen box-border;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
