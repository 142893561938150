import React from "react";

const SocialLink = ({ src }) => {
  return (
    <button className="social-link ">
      <img src={src} alt="social" className="h-6 w-6" />
    </button>
  );
};
export default SocialLink;
