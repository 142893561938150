import "./Footer.css";
import SocialLink from "../Button/SocialLink";

const Footer = () => {
  return (
    <div className="footer w-full">
      <div className="contentFooter">
        <div className="footer-text">
          &copy; 2024 Coinclap, All right reserved
        </div>
        <div className="footer-right-content-button">
          <a href="https://www.linkedin.com/company/coinclap/" target="_blank">
            <SocialLink src="/assests/images/linkedin.png" />
          </a>
          <a href="https://twitter.com/coinclapfinance" target="_blank">
            <SocialLink src="/assests/images/twitter.png" />
          </a>
          <a href="https://www.facebook.com/coinclap" target="_blank">
            <SocialLink src="/assests/images/facebook.png" />
          </a>

          <a href="https://www.instagram.com/coinclapfinance/" target="_blank">
            <SocialLink src="/assests/images/instagram.png" />
          </a>
        </div>
      </div>
    </div>
  );
};
export default Footer;
