// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.card {
    border-radius: 0.5rem;
    --tw-bg-opacity: 1;
    background-color: rgb(123 87 251 / var(--tw-bg-opacity));
    --tw-text-opacity: 1;
    color: rgb(255 255 255 / var(--tw-text-opacity))
}
.card-heading {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    font-size: 1.25rem;
    line-height: 1.75rem;
    font-weight: 700
}
@media (min-width: 640px) {
    .card-heading {
        font-size: 1.5rem;
        line-height: 2rem
    }
}
@media (min-width: 1160px) {
    .card-heading {
        font-size: 1.875rem;
        line-height: 2.25rem
    }
}
.card-desc {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
    font-size: 1rem;
    line-height: 1.5rem
}
.card-image {
    margin-top: 2rem;
    margin-bottom: 2rem;
    margin-left: auto;
    margin-right: auto;
    height: 118px;
    width: 118px;
    justify-content: center;
    justify-self: center;
    text-align: center
}`, "",{"version":3,"sources":["webpack://./src/components/Card/Card.css"],"names":[],"mappings":"AACI;IAAA,qBAKC;IALD,kBAKC;IALD,wDAKC;IALD,oBAKC;IALD;AAKC;AAGD;IAAA,oBAKG;IALH,qBAKG;IALH,kBAKG;IALH,oBAKG;IALH;AAKG;AALH;IAAA;QAAA,iBAKG;QALH;IAKG;AAAA;AALH;IAAA;QAAA,mBAKG;QALH;IAKG;AAAA;AAGH;IAAA,oBAGG;IAHH,qBAGG;IAHH,iBAGG;IAHH,oBAGG;IAHH,eAGG;IAHH;AAGG;AAGH;IAAA,gBAQG;IARH,mBAQG;IARH,iBAQG;IARH,kBAQG;IARH,aAQG;IARH,YAQG;IARH,uBAQG;IARH,oBAQG;IARH;AAQG","sourcesContent":[".card{\n    @apply\n    bg-purple-secondary rounded-lg text-white\n    /*\n    lg:ml-[15px] lg:mr-[15px] lg:p-[25px] \n    sm:ml-[5px] sm:mr-[5px] sm:p-[15px] sm:mb-[15px]\n    */\n}\n.card-heading{\n    @apply\n    font-bold  \n    lg:text-3xl\n    sm:text-2xl\n    text-xl\n    px-6\n}\n.card-desc{\n    @apply\n    text-base\n    px-6\n    py-4\n}\n.card-image{\n    @apply\n    w-[118px]\n    h-[118px]\n    justify-center\n    justify-self-center\n    text-center\n    ml-auto\n    mr-auto\n    my-8\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
