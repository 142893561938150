import React from 'react'
import '../FindMore/FindMore.css'
import Paper from './Paper';
const FindMore = ()=>{
    return(
        <>
        <div className='find-more-heading'>
            Find out more
        </div>
        <div className="find-more">
            <div className="find-more-left">
                    <p className="find-more-bold-text">
                    A new space for people to <span className="text-purple-secondary">learn finance</span>,<br/> manage, invest, and borrow funds!
                    </p>
                    <p className="find-more-narrow-text">Talk to people about finance tips, cashflow management, and many more</p>
            </div>
            <div className="papers ">
                <div className="paper-content "><Paper heading="Learn from peers, events & more" desc = "Financial literacy for everyone! Get tips from peers, join workshops, and discover more."/></div>
                <div className="paper-content "><Paper heading="Manage your cashflow" desc = "Take control of your money. Our app helps you manage your cashflow effectively."/></div>
                <div className="paper-content "><Paper heading="Invest on p2p lending & Digital Gold" desc = "Build your financial future by investing in P2P lending & Digital Gold. Get started with our app!"/></div>
                <div className="paper-content "><Paper heading="Borrow paperless loan" desc = "Stuck on Unexpected Expenses? Get a fast Loan in Minutes!"/></div>
                 
                 
                 
                 
            </div>
        </div>
        </>
    )
}
export default FindMore;