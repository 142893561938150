// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.snackbar_snackbar__gSwgw {

    position: relative;

    margin-left: auto;

    margin-right: auto;

    margin-bottom: 0.25rem;

    max-width: 20rem
}

@keyframes snackbar_fade-in__Zb1HP {

    0% {

        opacity: 0
    }

    100% {

        opacity: 1
    }
}

@keyframes snackbar_fade-out__CEc9X {

    0% {

        opacity: 1
    }

    100% {

        opacity: 0
    }
}

.snackbar_snackbar__gSwgw {

    animation: snackbar_fade-in__Zb1HP .5s ease-in forwards, snackbar_fade-out__CEc9X .5s 2s ease-out forwards;

    border-radius: 0.5rem;

    padding-top: 0.75rem;

    padding-bottom: 0.75rem;

    padding-left: 1.5rem;

    padding-right: 1.5rem;

    font-weight: 500;

    --tw-text-opacity: 1;

    color: rgb(255 255 255 / var(--tw-text-opacity))
}

.snackbar_snackbar__gSwgw img {

    position: absolute;

    right: 0.75rem;

    top: 1rem;

    cursor: pointer
}

.snackbar_toastWrapper__Je--o {

    position: fixed;

    top: 5rem;

    left: 0px;

    right: 0px;

    z-index: 100
}

.snackbar_err__5fWdw {

    --tw-bg-opacity: 1;

    background-color: rgb(239 68 68 / var(--tw-bg-opacity))
}

.snackbar_success__tjFsZ {

    --tw-bg-opacity: 1;

    background-color: rgb(34 197 94 / var(--tw-bg-opacity))
}

`, "",{"version":3,"sources":["webpack://./src/hooks/snackbar.module.css"],"names":[],"mappings":"AACE;;IAAA,kBAAqG;;IAArG,iBAAqG;;IAArG,kBAAqG;;IAArG,sBAAqG;;IAArG;AAAqG;;AAArG;;IAAA;;QAAA;IAAqG;;IAArG;;QAAA;IAAqG;AAAA;;AAArG;;IAAA;;QAAA;IAAqG;;IAArG;;QAAA;IAAqG;AAAA;;AAArG;;IAAA,0GAAqG;;IAArG,qBAAqG;;IAArG,oBAAqG;;IAArG,uBAAqG;;IAArG,oBAAqG;;IAArG,qBAAqG;;IAArG,gBAAqG;;IAArG,oBAAqG;;IAArG;AAAqG;;AAIrG;;IAAA,kBAA4C;;IAA5C,cAA4C;;IAA5C,SAA4C;;IAA5C;AAA4C;;AAI5C;;IAAA,eAA0C;;IAA1C,SAA0C;;IAA1C,SAA0C;;IAA1C,UAA0C;;IAA1C;AAA0C;;AAI1C;;IAAA,kBAAiB;;IAAjB;AAAiB;;AAIjB;;IAAA,kBAAmB;;IAAnB;AAAmB","sourcesContent":[".snackbar {\n  @apply animate-fade-in-out max-w-xs mx-auto text-white font-medium rounded-lg py-3 px-6 mb-1 relative;\n}\n\n.snackbar img {\n  @apply absolute right-3 top-4 cursor-pointer;\n}\n\n.toastWrapper {\n  @apply fixed z-[100] top-20 left-0 right-0;\n}\n\n.err {\n  @apply bg-red-500;\n}\n\n.success {\n  @apply bg-green-500;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"snackbar": `snackbar_snackbar__gSwgw`,
	"fade-in": `snackbar_fade-in__Zb1HP`,
	"fade-out": `snackbar_fade-out__CEc9X`,
	"toastWrapper": `snackbar_toastWrapper__Je--o`,
	"err": `snackbar_err__5fWdw`,
	"success": `snackbar_success__tjFsZ`
};
export default ___CSS_LOADER_EXPORT___;
