import React, { Component } from "react";
import "../Banner/Banner.css";
import Button from "../Button/Button";
const Banner = ({ openModal }) => {
  return (
    <div className="banner ">
      <div className="banner-left">
        <div className="grid">
          <p className="banner-left-text1">
            Step into the
            <br /> Future of{" "}
            <span className="text-purple-secondary">Finance!</span>
          </p>
        </div>
        <div className="grid">
          <p className="banner-left-text2">
            Secure Your Spot Now & Learn from others, manage finances,
            <br /> invest & borrow with confidence.
          </p>
        </div>

        <div className="banner-left-button">
          <Button
            buttonText="Join Waitlist"
            buttonColor="black"
            handleFunction={openModal}
          />
        </div>
      </div>
      <div className="banner-right">
        <img src="/assests/images/hand.png" className="hand-logo" />
      </div>
    </div>
  );
};

export default Banner;
