// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.footer {

    height: 60px;

    --tw-bg-opacity: 1;

    background-color: rgb(0 0 0 / var(--tw-bg-opacity));

    --tw-text-opacity: 1;

    color: rgb(255 255 255 / var(--tw-text-opacity))
}

@media not all and (min-width: 820px) {

    .footer {

        height: -moz-fit-content;

        height: fit-content
    }
}

.contentFooter {

    margin-left: auto;

    margin-right: auto;

    display: flex;

    max-width: 1160px;

    justify-content: space-between
}

@media not all and (min-width: 820px) {

    .contentFooter {

        flex-direction: column;

        align-items: center;

        justify-content: center;

        gap: 0.5rem
    }
}

@media not all and (min-width: 640px) {

    .contentFooter {

        padding-top: 0.5rem;

        padding-bottom: 0.5rem
    }
}

.footer-text {

    display: grid;

    justify-content: center;

    line-height: 40px;

    --tw-text-opacity: 1;

    color: rgb(255 255 255 / var(--tw-text-opacity))
}

@media (min-width: 640px) {

    .footer-text {

        height: 60px;

        justify-self: start;

        font-size: 14px;

        line-height: 60px
    }
}

@media (min-width: 820px) {

    .footer-text {

        font-size: 16px
    }
}

.footer-right-content-button {

    display: flex;

    align-items: center;

    justify-content: center
}
`, "",{"version":3,"sources":["webpack://./src/components/Footer/Footer.css"],"names":[],"mappings":"AACE;;IAAA,YAGc;;IAHd,kBAGc;;IAHd,mDAGc;;IAHd,oBAGc;;IAHd;AAGc;;AAHd;;IAAA;;QAAA,wBAGc;;QAHd;IAGc;AAAA;;AAId;;IAAA,iBAQa;;IARb,kBAQa;;IARb,aAQa;;IARb,iBAQa;;IARb;AAQa;;AARb;;IAAA;;QAAA,sBAQa;;QARb,mBAQa;;QARb,uBAQa;;QARb;IAQa;AAAA;;AARb;;IAAA;;QAAA,mBAQa;;QARb;IAQa;AAAA;;AAIb;;IAAA,aAQgB;;IARhB,uBAQgB;;IARhB,iBAQgB;;IARhB,oBAQgB;;IARhB;AAQgB;;AARhB;;IAAA;;QAAA,YAQgB;;QARhB,mBAQgB;;QARhB,eAQgB;;QARhB;IAQgB;AAAA;;AARhB;;IAAA;;QAAA;IAQgB;AAAA;;AAIhB;;IAAA,aAAuC;;IAAvC,mBAAuC;;IAAvC;AAAuC","sourcesContent":[".footer {\n  @apply bg-black\n    text-white\n    h-[60px]\n    max-md:h-fit;\n}\n\n.contentFooter {\n  @apply flex\n    justify-between\n    max-w-screen-lg\n    mx-auto\n    max-md:flex-col\n    max-md:gap-2\n    max-md:justify-center\n    max-md:items-center\n    max-sm:py-2;\n}\n\n.footer-text {\n  @apply grid \n    sm:justify-self-start \n    justify-center\n    text-white \n    sm:h-[60px] \n    sm:leading-[60px]\n    leading-[40px]\n    sm:text-[14px]\n    md:text-[16px];\n}\n\n.footer-right-content-button {\n  @apply flex items-center justify-center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
