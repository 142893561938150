import React, { useMemo } from "react";
import classNames from "classnames";
import styles from "./snackbar.module.css";

const SnackbarContext = React.createContext({});

function generateRandomId() {
  let first = (Math.random() * 46656) | 0;
  let second = (Math.random() * 46656) | 0;
  first = ('000' + first.toString(36)).slice(-3);
  second = ('000' + second.toString(36)).slice(-3);

  return first + second;
}

const SnackbarComponent = ({ close, children, status, win, cashout }) => {
  React.useEffect(() => {
    let timer = setTimeout(close, 3000);

    return () => clearTimeout(timer);
  }, []);

  return (
    <>
      <div
        className={classNames(styles.snackbar, {
          [styles.err]: status === "err",
          [styles.success]: status === "success",
        })}
      >
        {children}
        <image
          src={"icons/closeV2.png"}
          alt="close"
          height={12}
          width={12}
          onClick={close}
        />
      </div>
    </>
  );
};

function SnackbarProvider({ children }) {
  const [toasts, setToasts] = React.useState([]);

  const showSnackbar = (content) => {
    if(!content?.msg && content?.status !== "win") return
    setToasts((currentToasts) => [
      ...currentToasts,
      { id: generateRandomId(), ...content },
    ]);
  }

  const close = (id) =>
    setToasts((currentToasts) =>
      currentToasts.filter((toast) => toast.id !== id)
    );

  const contextValue = useMemo(() => ({ showSnackbar }), []);

  return (
    <SnackbarContext.Provider value={contextValue}>
      <div className={classNames(styles.toastWrapper)}>
        {toasts.map((toast) => (
          <SnackbarComponent
            key={toast?.id}
            status={toast?.status || "err"}
            close={() => close(toast.id)}
            win={toast?.win || ""}
            cashout={toast?.cashout || ""}
          >
            {toast?.msg}
          </SnackbarComponent>
        ))}
      </div>
      {children}
    </SnackbarContext.Provider>
  );
}

const Snackbar = () => {
  const { showSnackbar } = React.useContext(SnackbarContext);
  return { showSnackbar };
};

export { SnackbarProvider, Snackbar };
