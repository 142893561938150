// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header {
    box-sizing: border-box;
    display: grid;
    height: 60;
    --tw-bg-opacity: 1;
    background-color: rgb(0 0 0 / var(--tw-bg-opacity));
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    --tw-text-opacity: 1;
    color: rgb(255 255 255 / var(--tw-text-opacity))
}
.header-content {
    margin-left: 2%;
    margin-right: 2%;
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    line-height: 50px
}
@media (min-width: 640px) {
    .header-content {
        margin-left: 12%;
        margin-right: 12%
    }
}
@media (min-width: 1160px) {
    .header-content {
        line-height: 70px
    }
}

.logo {
    display: grid;
    width: 160px;
    align-self: center
}

@media not all and (min-width: 820px) {
    .logo {
        width: 120px
    }
}

.header-right {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    align-items: center;
    justify-content: flex-end;
    justify-self: end
}
.header-right-content {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    text-align: right
}
.header-right-text {
    display: none;
    align-items: flex-end;
    justify-content: flex-end;
    text-align: right;
    font-family: Poppins, sans-serif;
    font-weight: 400;
    --tw-text-opacity: 1;
    color: rgb(255 255 255 / var(--tw-text-opacity))
}
@media (min-width: 820px) {
    .header-right-text {
        margin-right: 0.5rem;
        display: block;
        font-size: 12px;
        line-height: 16px
    }
}
@media (min-width: 1160px) {
    .header-right-text {
        margin-right: 1rem;
        font-size: 14px;
        line-height: 18px
    }
}
@media (min-width: 1400px) {
    .header-right-text {
        margin-right: 2rem;
        font-size: 16px;
        line-height: 24px
    }
}
`, "",{"version":3,"sources":["webpack://./src/components/Header/Header.css"],"names":[],"mappings":"AACE;IAAA,sBAKY;IALZ,aAKY;IALZ,UAKY;IALZ,kBAKY;IALZ,mDAKY;IALZ,mBAKY;IALZ,sBAKY;IALZ,oBAKY;IALZ;AAKY;AAGZ;IAAA,eAKa;IALb,gBAKa;IALb,aAKa;IALb,gDAKa;IALb;AAKa;AALb;IAAA;QAAA,gBAKa;QALb;IAKa;AAAA;AALb;IAAA;QAAA;IAKa;AAAA;;AAIb;IAAA,aAIa;IAJb,YAIa;IAJb;AAIa;;AAJb;IAAA;QAAA;IAIa;AAAA;;AAIb;IAAA,aAGa;IAHb,gDAGa;IAHb,mBAGa;IAHb,yBAGa;IAHb;AAGa;AAGb;IAAA,aAGY;IAHZ,qBAGY;IAHZ,yBAGY;IAHZ;AAGY;AAGZ;IAAA,aAoBQ;IApBR,qBAoBQ;IApBR,yBAoBQ;IApBR,iBAoBQ;IApBR,gCAoBQ;IApBR,gBAoBQ;IApBR,oBAoBQ;IApBR;AAoBQ;AApBR;IAAA;QAAA,oBAoBQ;QApBR,cAoBQ;QApBR,eAoBQ;QApBR;IAoBQ;AAAA;AApBR;IAAA;QAAA,kBAoBQ;QApBR,eAoBQ;QApBR;IAoBQ;AAAA;AApBR;IAAA;QAAA,kBAoBQ;QApBR,eAoBQ;QApBR;IAoBQ;AAAA","sourcesContent":[".header {\n  @apply grid\n    h-[60]\n    py-2\n    bg-black\n    text-white\n    box-border;\n}\n.header-content {\n  @apply grid\n    sm:mx-[12%]\n    mx-[2%]\n    lg:leading-[70px]\n    leading-[50px]\n    grid-cols-2;\n}\n\n.logo {\n  @apply w-[160px]\n  max-md:w-[120px]\n    \n    grid \n    self-center;\n}\n\n.header-right {\n  @apply grid grid-cols-2 \n    justify-self-end \n    items-center\n    justify-end;\n}\n.header-right-content {\n  @apply flex \n    justify-end \n    items-end\n    text-right;\n}\n.header-right-text {\n  @apply text-white \n    font-poppins \n    font-[400] \n    text-right\n    \n    justify-end\n    items-end\n    xl:text-[16px] \n    xl:leading-[24px] \n    xl:mr-8\n\n    lg:text-[14px] \n    lg:leading-[18px] \n    lg:mr-4\n\n    md:text-[12px] \n    md:leading-[16px] \n    md:mr-2\n\n    md:block\n    hidden;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
