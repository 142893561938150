// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.label {
  margin-bottom: 0.25rem;
  display: block;
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-weight: 400;
  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity));
}
.text-input {
  width: 100%;
  appearance: none;
  border-radius: 0.25rem;
  border-width: 1px;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 0.25rem;
  padding-right: 0.25rem;
  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity));
  --tw-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.no-scroll {
  overflow: hidden;
}

.btnContainer {
  margin-top: 0.5rem;
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  align-items: center;
  justify-content: flex-end;
}

.btnContainer > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(0.5rem * var(--tw-space-x-reverse));
  margin-left: calc(0.5rem * calc(1 - var(--tw-space-x-reverse)));
}

.btnContainer button {
  width: 100%;
  border-radius: 1.5rem;
}`, "",{"version":3,"sources":["webpack://./src/components/FormModal/FormModal.css"],"names":[],"mappings":"AACE;EAAA,sBAAgD;EAAhD,cAAgD;EAAhD,mBAAgD;EAAhD,oBAAgD;EAAhD,gBAAgD;EAAhD,oBAAgD;EAAhD;AAAgD;AAGhD;EAAA,WAAwE;EAAxE,gBAAwE;EAAxE,sBAAwE;EAAxE,iBAAwE;EAAxE,mBAAwE;EAAxE,sBAAwE;EAAxE,qBAAwE;EAAxE,sBAAwE;EAAxE,oBAAwE;EAAxE,0CAAwE;EAAxE,0EAAwE;EAAxE,8FAAwE;EAAxE;AAAwE;AAE1E;EACE,gBAAgB;AAClB;;AAGE;EAAA,kBAA+D;EAA/D,aAA+D;EAA/D,gDAA+D;EAA/D,mBAA+D;EAA/D;AAA+D;;AAA/D;EAAA,uBAA+D;EAA/D,sDAA+D;EAA/D;AAA+D;;AAI7D;EAAA,WAAwB;EAAxB;AAAwB","sourcesContent":[".label {\n  @apply block text-black text-sm font-normal mb-1;\n}\n.text-input {\n  @apply shadow appearance-none border rounded w-full py-2 px-1 text-black;\n}\n.no-scroll {\n  overflow: hidden;\n}\n\n.btnContainer {\n  @apply grid grid-cols-2 items-center justify-end space-x-2 mt-2;\n}\n\n.btnContainer button {\n    @apply w-full rounded-3xl\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
