import './Button.css'
const Button = ({buttonText, buttonColor, handleFunction})=> {
    
    return (
        <button 
            className={`button button-${buttonColor}`}
            onClick={handleFunction}
        >
            {buttonText}
        </button>
    );
}
export default Button;