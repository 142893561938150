import React from "react";
import "../KnowAboutUs/KnowAboutUs.css";
import Card from "../Card/Card";
import Button from "../Button/Button";
import DownloadApp from "../Button/DownloadApp";
const KnowAboutUs = () => {
  return (
    <div className="know-about-us">
      <h1 className="know-about-us-heading">Know About Us</h1>
      <p className="know-about-us-text">
        We empower you to grow financially. Our community platform combines
        social learning with financial tool and products. Learn from others,
        manage your money, invest wisely, and access funds to reach your goals –
        all in one place.
      </p>
      <div className="know-about-us-cards">
        <Card
          heading="Be Bold"
          description="Embrace innovation, challenge the norm, and take calculated risks to drive financial progress."
          image="assests/images/Bold.png"
        />
        <Card
          heading="Curious"
          description="Continuously seek new financial knowledge, explore different perspectives, and adopt a growth mindset."
          image="assests/images/Curious.png"
        />
        <Card
          heading="Empower"
          description="Equip individuals with the tools and knowledge to take control of their financial future. Create an environment of trust and autonomy."
          image="assests/images/Empower.png"
        />
      </div>
      <div className=" know-about-us-banner">
        <div className="know-about-us-left">
          <p className="know-about-us-left-text">
          Join the #finance 
            <br /> community today!
          </p>
          <p className="text-white mt-3 mb-3">
          The only social platform that helps you get your finance insights faster.
          </p>
          <img
            src="/assests/images/mobile-logo-2.png"
            className="know-about-us-hand-logo-responsive"
          />
          <p className="text-white sm:mb-1 sm:mt-1 mb-3 mt-3">
            Available soon on
          </p>
          <div className="download-button-row">
            <DownloadApp
              logosrc="/assests/images/googleplay.png"
              action="GET IT ON"
              appsource="Google Play"
            />
            <DownloadApp
              logosrc="/assests/images/applelogo.png"
              action="Download on the"
              appsource="App Store"
            />
          </div>
        </div>
        <img
          src="/assests/images/mobile-logo-2.png"
          className="know-about-us-hand-logo"
        />
      </div>
      <div className="connect">
        <div className="grid items-center">
          <p className="connect-text">
            Hello,
            <br /> Want to connect?
          </p>
        </div>
        <div className="grid items-center justify-end">
          <Button buttonText="Download our deck" buttonColor="black" />
        </div>
      </div>
      <div className="know-about-us-deck-container">
        <p>Hello, <br/>Want to connect ?</p>
        <button>Download Our Deck</button>
      </div>
    </div>
  );
};
export default KnowAboutUs;
