import React from 'react'

const DownloadApp = ({ logosrc, action, appsource }) => {
    return (
        <button className='download-button'>
            <div className="download-button-content">
                <div className="grid">
                    <img src={logosrc} className='download-button-logo' />
                </div>
                <div className="grid text-left ">
                    <div className='download-button-narrow-text'>{action}</div>
                    <div className='download-button-bold-text'>{appsource}</div>
                </div>
            </div>
        </button>
    );
}
export default DownloadApp;