import "./Header.css";
import Button from "../Button/Button";
export const Header = ({ openModal }) => {
  return (
    <div className="header">
      <div className="header-content ">
        <img
          src="/assests/images/coinclap-logo.png"
          alt="Coinclap"
          className="logo"
        />
        <div className="header-right">
          <div className="header-right-content">
            <p className="header-right-text">
              Join the waitlist,
              <br />
              Be rewarded for life.
            </p>
          </div>
          <div className="header-right-content">
            <Button
              buttonText="Join Waitlist"
              buttonColor="white"
              handleFunction={openModal}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
export default Header;
