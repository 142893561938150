import React from 'react'
import '../Card/Card.css'
const Card = ({heading, description, image})=> {
    return (
        <div className='card'>
            <img className="card-image" src={image}/>
            <h1 className='card-heading'>{heading}</h1>
            <p className='card-desc'>{description}</p>
        </div>
    );
}
export default Card;